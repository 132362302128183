<template>
	<body class="auth-wrapper">
		<div class="auth-wrapper auth-bg auth-v2">
			<!-- Brand logo-->
			<nav class="navbar auth-bg">
				<div class="container-fluid  auth-bg p-0">
					<b-link class="brand-logo mx-auto ml-md-4 mb-2">
						<logo-sao-bento />
					</b-link>
				</div>
			</nav>

			<template v-if="sentEmail">
				<b-col
					sm="12"
					md="6"
					class="mx-auto flex-wrap rounded p-1 justify-content-center mt-3 mt-lg-2 py-3 border border-md rounded"
				>
					<b-row class="d-flex justify-content-end mx-auto my-1 mr-1">
						<span @click="goToHome" class="d-flex mb-0 mr-2" style="cursor: pointer">
							<feather-icon icon="ChevronLeftIcon" class="my-auto" size="20" />
							<p class="my-auto font-medium-1 mr-2 mr-md-0">Voltar</p>
						</span>
					</b-row>

					<b-col sm="12" class="mx-auto">
						<b-card-title class="font-weight-bold font-large-1 mb-1 text-center">
							Cadastrar conta
						</b-card-title>

						<b-card-text class="text-center m-0">
							<p class="mb-1">
								Para criarmos seu primeiro acesso informe o número do seu CPF ou CNPJ.
							</p>
						</b-card-text>

						<b-col sm="12" class="mx-auto">
							<b-alert v-model="showAlert" :variant="alertVariant" dismissible class="mb-0 mt-2">
								<div class="alert-body">
									<p>{{ alertMessage }}</p>
								</div>
							</b-alert>

							<validation-observer ref="loginValidation">
								<b-form class="auth-login-form mt-2" @submit.prevent>
									<!-- CPF ou CNPJ -->
									<b-form-group label="CPF ou CNPJ" label-for="user">
										<validation-provider #default="{ errors }" name="CPF ou CNPJ" rules="required">
											<b-form-input
												id="cpf-cnpj"
												v-model="login"
												:state="errors.length > 0 ? false : null"
												name="cpf-cnpj"
												placeholder=""
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>

									<!-- submit buttons -->
									<b-button type="submit" variant="primary" block @click="cadastrar()">
										<b-spinner v-if="isLoading" small />
										<span v-else>Cadastrar</span>
									</b-button>
								</b-form>
							</validation-observer>
						</b-col>
					</b-col>
				</b-col>
			</template>

			<template v-else>
				<b-col
					sm="12"
					md="6"
					class="mx-auto flex-wrap rounded p-1 justify-content-center mt-3 mt-lg-2 py-3 border border-md round"
				>
					<b-row class="d-flex justify-content-end mx-auto my-1 mr-1">
						<span @click="goToHome" class="d-flex mb-0 mb-md-2 mr-2" style="cursor: pointer">
							<feather-icon icon="ChevronLeftIcon" class="my-auto" size="20" />
							<p class="my-auto font-medium-1 mr-2 mr-md-0">Voltar</p>
						</span>
					</b-row>

					<b-col sm="12" class="mx-auto">
						<b-card>
							<b-card-title class="font-weight-bold font-large-1 mb-1 text-left text-md-center">
								Estamos quase lá!
							</b-card-title>

							<b-card-text class="font-medium-2 " style="line-height:150%">
								Enviamos um e-mail para <b>{{ email }}</b> com as instruções para definição de sua
								senha de acesso.
							</b-card-text>
							<b-button type="submit" variant="primary" block @click="goToHome()">
								<span>Voltar para tela de login</span>
							</b-button>
						</b-card>
					</b-col>
				</b-col>
			</template>
		</div>
	</body>
</template>

<script>
import LogoSaoBento from '@/layouts/components/LogoSaoBento.vue'
import {
	BCol,
	BCardTitle,
	BRow,
	BLink,
	BFormGroup,
	BFormInput,
	BButton,
	BCardText,
	BSpinner,
	BForm,
	BCard,
	BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import loginValidation from '@/utils/loginValidation.js'
import services from '@/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		LogoSaoBento,
		BCol,
		BCardTitle,
		BRow,
		BLink,
		BFormGroup,
		BFormInput,
		BButton,
		BCardText,
		BForm,
		BSpinner,
		BCard,
		BAlert,
		ValidationProvider,
		ValidationObserver,
	},

	data() {
		return {
			isLoading: false,
			sentEmail: true,
			login: '',
			email: '',
			required,
			loginValidation,
			showAlert: false,
			alertVariant: 'success',
			alertMessage: '',
		}
	},

	methods: {
		goToHome() {
			this.$router.push('/login')
		},

		async validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.cadastrar()
				}
			})
		},

		async getEmailByCpfCnpj() {
			this.email = await services.auth.getMaskedEmailByCpfCnpj(
				this.login,
				this.$apolloProvider.defaultClient
			)
		},

		async cadastrar() {
			this.isLoading = true
			this.showAlert = false

			if (loginValidation.isCpfOrCnpj(this.login)) {
				const { errors } = await services.auth.sendResetEmail(
					this.login,
					this.$apolloProvider.defaultClient
				)
				if (errors) {
					if (errors == 'Firebase: Error (auth/too-many-requests).') {
						this.showAlert = true
						this.alertVariant = 'info'
						this.alertMessage = 'Estamos com dificuldades agora, tente novamente mais tarde :('
					} else {
						this.showAlert = true
						this.alertVariant = 'danger'
						this.alertMessage =
							'Não foi possível localizar o contrato vinculado ao seu CPF/CNPJ. Entre em contato com o setor de atendimento ao cliente para atualização dos dados cadastrais.'
					}
				} else {
					this.sentEmail = !this
					this.getEmailByCpfCnpj()
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Tudo certo! :)',
							icon: 'EditIcon',
							variant: 'success',
							text: 'Enviamos um email com as instruções para criar sua conta!',
						},
					})
				}
			} else {
				this.showAlert = true
				this.alertVariant = 'danger'
				this.alertMessage = 'Insira um CPF ou CNPJ válido'
			}

			this.isLoading = false
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';

.brand-logo {
	svg {
		height: 80px;
		width: 170px;
	}
}

@media (max-width: 767px) {
	.border-md {
		border: none !important;
	}
}

.bg-gray {
	background-color: #9597990e;
}
</style>
